import React from "react";
import styled from "styled-components";
import quotesArt from "./quotes.svg";

export const Grid = styled.div.attrs({
  className: "grid gap-x-m"
})`
  grid-template-columns: 1fr 150px;
  grid-template-rows: 150px auto;
  grid-template-areas: 
    "story avatar"
    "story companypicture"
    "link link"
    "author author"
    "company company"
`;

export const Testimonial = ({ children, ...props }) => {
  return (
    <div {...props}>
      <img src={quotesArt} alt="quotes" />
      <Grid>{children}</Grid>
    </div>
  )
}

