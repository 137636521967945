import React from "react";
import cx from "classnames";
import styled from "styled-components";

export const Indicator = styled.div.attrs({
  className: cx("w-s", "h-xl")
})`
  background-image: linear-gradient(180deg, #88D5F0 0%, #D8D8D8 100%);
  border-radius: 3px;
`;

export const ScrollBait = ({ className, ...props }) => {
  return (
    <div {...props} className={cx("w-full", "flex", "justify-center", className)}>
      <Indicator />
    </div>
  )
}