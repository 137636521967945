import React from "react"
import cx from "classnames";
import { Container } from "../Container";

export const Hero = React.forwardRef(({ children, className }, ref) => {
  return (
    <Container ref={ref} className={cx("flex" ,"flex-col", className)}>
      {children}
    </Container>
  )
})
