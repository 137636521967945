import React, { useEffect, useState, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { times } from "../util/times";
import { MdCached } from "react-icons/md"
import cx from "classnames"
import { navigate, graphql } from "gatsby"
import styled, { keyframes, css, createGlobalStyle } from "styled-components"
import Img from "gatsby-image"
import { WaterBackground } from "../components/WaterBackground"
import { Button } from "../components/Button"
import { usePreferredLanguage } from "../util/usePreferredLanguage"
import { PageContext } from "../contexts/PageContext"
import { Container } from "../components/Container"
import {
  Testimonial,
  TestimonialStory,
  TestimonialAvatar,
  TestimonialCompanyPicture,
  TestimonialLink,
  TestimonialAuthor,
  TestimonialCompany,
} from "../components/Testimonial"
import { ScrollBait } from "../components/ScrollBait"
import { Heading, HeadingTitle, HeadingSubtitle } from "../components/Heading"
import { StrapiMobileTopAction } from "../strapi/StrapiMobileTopAction"
import { StrapiFooter } from "../strapi/StrapiFooter"
import { StrapiTopBar } from "../strapi/StrapiTopBar"
import { StrapiNavigation } from "../strapi/StrapiNavigation"
import ReactMarkdown from "react-markdown"
import { theme } from "twin.macro"
import {
  Hero,
  HeroTitle,
  HeroDescription,
  HeroActions,
  HeroAction,
} from "../components/Hero"

import "../styles.css"

const isBrowser = typeof window !== `undefined`

const GlobalStyles = createGlobalStyle`
  html {
    --navbar-height: 112px;
    @media (min-width: ${theme("screens.md")}) { 
      --navbar-height: 60px;
    };
  }
`

const slideIn = ({ from }) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${from === "top" ? "-100%" : "100%"});
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const slideOut = ({ from }) => keyframes`
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 1;
    transform: translateY(${from === "top" ? "-100%" : "100%"});
  }
`

const HeroBackground = styled.div.attrs({
  className: "fixed top-0 w-full h-screen",
})`
  z-index: -1;
  padding-top: var(--navbar-height);
  > * {
    height: calc(100vh - var(--navbar-height));
  }
`

const HeroSize = styled.div.attrs({
  className: "relative w-full",
})`
  height: calc(100vh - var(--navbar-height));
`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

const useScrollPosition = ({ ref }) => {
  const [top, setTop] = useState(0)

  useEffect(() => {
    const scrollListener = () => {
      const boundingRect = ref.current?.getBoundingClientRect() || { top: 0 }
      const top = boundingRect.top
      setTop(top)
    }
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [ref])

  return top
}

const Card = styled.div.attrs({
  className: "flex flex-col rounded-lg",
})``

const CardMedia = styled.div.attrs({
  className: "",
})``

const CardContent = styled.div.attrs({
  className: "p-m",
})``

const VideoSectionRenderer = ({ data }) => {
  const features = data.features.map(f => (
    <Card key={f.id}>
      <div className="flex justify-center align-center pt-l text-blue-500">
        <img
          src={f.icon.localFile.publicURL}
          alt={"feature"}
          className={"w-xl"}
        />
      </div>
      <CardContent>
        <h3 className="heading-6 font-bold pb-s text-center">{f.title.text}</h3>
        <p className="text-center">
          <ReactMarkdown>{f.description.text}</ReactMarkdown>
        </p>
      </CardContent>
    </Card>
  ))
  return (
    <Container as="section" className="flex flex-col items-center my-xl">
      <Heading level="h2" className="text-center mb-l">
        <HeadingSubtitle>{data.headline.subtitle.text}</HeadingSubtitle>
        <HeadingTitle>{data.headline.title.text}</HeadingTitle>
      </Heading>
      <div className="w-full max-w-3xl w-full">
        <div className="aspect-w-16 aspect-h-9 w-full bg-gray-700 rounded-lg overflow-hidden shadow-2xl">
          <iframe
            title={"QANDR Introduction"}
            src={`https://www.youtube.com/embed/${data.video.videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 w-full content-center gap-x-m md:gap-x-l gap-y-m mt-l md:mt-xl">
        {features}
      </div>
    </Container>
  )
}

const TestimonialsSectionRenderer = ({ data }) => {
  const testimonials = data.testimonials.map(({ testimonial: t }) => {
    return (
      <Testimonial className="lg:rounded-lg p-m">
        <TestimonialStory>
          <ReactMarkdown>{t.story.text}</ReactMarkdown>
        </TestimonialStory>
        <TestimonialAvatar>
          <Img fixed={t.avatar.localFile.childImageSharp.fixed} />
        </TestimonialAvatar>
        <TestimonialCompanyPicture>
          <Img fixed={t.companyImage.localFile.childImageSharp.fixed} />
        </TestimonialCompanyPicture>
        <TestimonialLink href={"/testimonial"}>
          read the complete story
        </TestimonialLink>
        <TestimonialAuthor>{t.personName}</TestimonialAuthor>
        <TestimonialCompany>{t.personCompany}</TestimonialCompany>
      </Testimonial>
    )
  })

  return (
    <Container as="section" className="flex flex-col items-center my-xl">
      <Heading level="h2" className="text-center mb-l">
        <HeadingSubtitle>{data.headline.subtitle.text}</HeadingSubtitle>
        <HeadingTitle>{data.headline.title.text}</HeadingTitle>
      </Heading>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-xl gap-y-l">
        {testimonials}
        <div className="flex justify-end items-end xl:col-span-full">
          <Button type="primary-outline" size="l">
            {data.buttonText.text}
          </Button>
        </div>
      </div>
    </Container>
  )
}

const CompaniesSectionRenderer = ({ data }) => {
  const links = data.links.map((link) => {
    let img;
    if(link.image.localFile.childImageSharp) {
      img = (
        <Img
          className="m-auto h-full w-full"
          fluid={link.image.localFile.childImageSharp.fluid} 
          alt={link.altText.text} 
          imgStyle={{
            objectFit: "contain"
          }}
          objectPosition="50% 50%"
        />
      )
    } else {
      img = (
        <img className="w-full" style={{ maxWidth: "150px", objectFit: "contain" }} src={link.image.localFile.publicURL} alt={link.altText.text} />
      )
    }
    return (
      <div className="relative aspect-w-16 aspect-h-9">
        <a className="transform absolute hover:shadow-lg w-full h-full flex justify-center rounded-md transition duration-500 ease-in-out hover:scale-125" href="/">
          {img}
        </a>
      </div>
    );
  });

  return (
    <Container as="section" className="flex flex-col items-center my-xl">
      <Heading level="h2" className="text-center mb-l">
        <HeadingSubtitle>{data.headline.subtitle.text}</HeadingSubtitle>
        <HeadingTitle>{data.headline.title.text}</HeadingTitle>
      </Heading>
      <p className="max-w-prose">
        <ReactMarkdown>{data.text.text}</ReactMarkdown>
      </p>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-x-m gap-y-l mt-l md:mt-xl">
        {links}
      </div>
    </Container>
  )
};
const SectionRenderer = ({ data: { __typename, ...sectionData } }) => {
  switch (__typename) {
    case "STRAPI_ComponentSectionsVideoSection": {
      return <VideoSectionRenderer data={sectionData} />
    }
    case "STRAPI_ComponentSectionsTestimonialsSection": {
      return <TestimonialsSectionRenderer data={sectionData} />
    }
    case "STRAPI_ComponentSectionsCompaniesSection": {
      return <CompaniesSectionRenderer data={sectionData} />
    }
    default:
      return null
  }
}

export default function LandingPage({ pageContext, data }) {
  const {
    navigateToPreferredLanguage,
    language,
    defaultLanguage,
    languageLinks,
  } = pageContext

  const { ref: heroRef, inView: heroInView, entry } = useInView({
    threshold: 0.5,
  })

  const [preferredLang] = usePreferredLanguage({
    defaultLanguage,
    languages: Object.keys(languageLinks),
  })

  useEffect(() => {
    if (preferredLang !== language && navigateToPreferredLanguage) {
      navigate(languageLinks[preferredLang])
    }
  }, [language, languageLinks, navigateToPreferredLanguage, preferredLang])

  const heroActions = data.strapi.landingPage?.hero?.actions.map(action => {
    return (
      <HeroAction type={action.type} key={action.id}>
        <ReactMarkdown>{action.content.text}</ReactMarkdown>
      </HeroAction>
    )
  })

  const sections = data.strapi.landingPage.sections.map(data => (
    <React.Fragment key={`${data.__typename}__${data.id}`}>
      <ScrollBait className="-my-l" />
      <SectionRenderer data={data} />
    </React.Fragment>
  ))

  return (
    <PageContext.Provider value={{ ...pageContext, data }}>
      <GlobalStyles />
      <StrapiMobileTopAction />
      <div className="sticky top-0 z-50">
        <StrapiTopBar>
          <StrapiNavigation />
        </StrapiTopBar>
      </div>
      <div className="relative w-full z-0">
        <HeroBackground>
          <div className="relative">
            <WaterBackground
              color="#67b2d1"
              className={cx(
                "absolute",
                "w-full",
                "h-2/3",
                "sm:h-1/2",
                "left-0",
                "bottom-0",
                "z-0",
                "transform",
                heroInView ? "translate-y-0" : "translate-y-full",
                "transition-transform",
                "duration-500",
                "ease-in-out"
              )}
            />
          </div>
        </HeroBackground>

        <HeroSize className="relative">
          <Hero className="relative z-10 h-full" ref={heroRef}>
            <HeroTitle className="h-1/3 sm:h-1/2">
              <ReactMarkdown>
                {data.strapi.landingPage?.hero?.headline?.text}
              </ReactMarkdown>
            </HeroTitle>
            <HeroDescription>
              <ReactMarkdown>
                {data.strapi.landingPage?.hero?.description?.text}
              </ReactMarkdown>
            </HeroDescription>
            <HeroActions>{heroActions}</HeroActions>
          </Hero>
        </HeroSize>
        {sections}
        <StrapiFooter />
      </div>
    </PageContext.Provider>
  )
}

export const query = graphql`
  query LandingPageData {
    strapi {
      ...NavigationActions
      ...NavigationMenus
      ...Footer
      ...LandingPage
    }
  }
`
